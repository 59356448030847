.radio-custom .ant-radio-wrapper {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 4px;
  transition: border-color 0.3s ease;
}

.radio-custom .ant-radio-wrapper-checked {
  border: 1px solid #1677ff;
  border-radius: 8px;
  padding: 4px;
  transition: border-color 0.3s ease;
}

.radio-custom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.radio-custom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.radio-custom .ant-radio-wrapper:hover {
  border-color: #1677ff;
}

.radio-custom .ant-radio:checked + * {
  background-color: rgba(22, 119, 255, 0.1);
}
