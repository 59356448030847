@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-chapter {
    @apply text-[30px] md:text-[42px] font-bold;
  }
  .text-section {
    @apply text-[16px] md:text-[20px] font-thin leading-[28px] md:leading-[32px];
  }
  .text-subsection {
    @apply text-[14px] md:text-[18px] font-thin leading-[20px] md:leading-[24px];
  }
  .text-paragraph {
    @apply text-[13px] md:text-[15px] font-normal leading-[15px] md:leading-[17px];
  }
  .text-subparagraph {
    @apply text-[11px] md:text-[13px] font-normal leading-[14px] md:leading-[15px];
  }
}
