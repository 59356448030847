@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .padding-x {
    @apply px-5 md:px-[150px];
  }
  .padding-y {
    @apply py-14;
  }
}
